/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../../_library/shared/_styles/modal.scss';
@import '../../_library/shared/_styles/buttons.scss';
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&display=swap');

.Oswald {
  font-family: 'Oswald', sans-serif !important;
}


@layer base {
  .dark-gradient {
    @apply bg-gradient-to-r from-dark-600 to-dark-300
  }
  .rcrt-main {
    @apply bg-gray-100 w-full
  }
  .rcrt-nav {
    @apply flex w-full bg-gray-300 px-2 pt-2 justify-between
  }
  .rcrt-nav-btn {
    @apply text-gray-400 bg-gray-100 hover:bg-gray-600 flex place-content-center py-1 px-2 min-w-12 mr-1 sm:mr-2 md:mr-3 rounded-md text-sm font-semibold rounded-b-none
  }
  .rcrt-nav-btn.active {
    @apply text-dark bg-accent
  }
  .rcrt-subnav-btn {
    @apply text-dark bg-gray-200 hover:bg-accent hover:cursor-pointer flex place-content-center py-1 px-1.5 mr-1 rounded-md text-xs font-semibold rounded-b-none
  }
  .rcrt-subnav-btn.active {
    @apply bg-accent hover:bg-light
  }
  .rcrt-content {
    @apply bg-white min-h-screen  p-1
  }
  .rcrt-title {
    @apply w-full p-2 bg-white
  }
  .rcrt-sticky-sub-nav {
    @apply sticky z-[2]
  }
  .rcrt-sticky-sub-nav-tabs {
    @apply sticky z-[2]
  }
  .rcrt-sticky-player-profile-tabs {
    @apply sticky z-[2]
  }
  .rcrt-sub-content {
    @apply bg-gray-100
  }
  //.rcrt-sub-content.sticky-signed-in {
  //  @apply sticky top-[106px]
  //}
  //.rcrt-sub-content.sticky-signed-out {
  //  @apply sticky top-[61px]
  //}
  //.rcrt-search {
  //  @apply bg-white shadow sticky top-[65px]
  //}
  //.rcrt-search.sticky-signed-in {
  //  @apply sticky top-[145px]
  //}

  .rcrt-list {
    @apply divide-y divide-gray-100 w-full
  }
  .rcrt-list-item {
    @apply flex flex-col bg-white hover:bg-gray-200 cursor-pointer border-b border-gray-200 w-full p-2 z-0
  }
  .rcrt-action-btn {
    @apply btn-accent btn-sm whitespace-nowrap
  }
  .rcrt-spinner {
    @apply my-8 mx-auto h-6 w-6 text-accent
  }
  .chicklet {
    @apply cursor-pointer rounded-md items-center py-0.5 px-2 mr-1.5 text-sm font-medium bg-dark-100 text-dark-700;
  }

  .btn-orange {
    @apply inline-flex items-center py-2 px-4 justify-center border border-transparent shadow-sm font-medium rounded-md text-white bg-gradient-to-r from-orange-500 to-orange-200 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-cbdib-orange mr-1 disabled:bg-gray-200
  }

  .social-btn {
    @apply flex flex-col text-xxs space-y-1.5 place-items-center hover:font-bold
  }
  .social-icon {
    @apply h-3 w-3
  }

}

button {
  @apply rounded-none
}
